import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ApiService } from '../../common/services/api.service';
import { ConfigService } from '../../config/config.service';
import { MoveBoxMaster } from '../../models/box-move.model';
import { Box } from '../../models/box.model';
import { BoxParts } from '../../models/location-parts.model';
import { Location } from '../../models/location.model';

@Injectable({
  providedIn: 'root'
})
export class LocationService {

  constructor(private apiService: ApiService, private configService: ConfigService) { }

  getAllLocations(companyId: number): Observable<Location[]> {
    return this.apiService.get(`${ this.configService.Settings.apiServerHost }/${ this.configService.Settings.locationUri }/${ companyId }`)
  }

  getLocation(companyId: number, locationId: number) : Observable<Location> {
    return this.apiService.get(`${ this.configService.Settings.apiServerHost }/${ this.configService.Settings.locationUri }/${ companyId }/${ locationId }`);
  }

  save(location: Location) {
    if (location.id == 0) 
      return this.apiService.post(location, this.configService.Settings.apiServerHost + this.configService.Settings.locationUri);
    else
      return this.apiService.put(location, this.configService.Settings.apiServerHost + this.configService.Settings.locationUri + `/${ location.id }`);
  }

  delete(id: number) {
    return this.apiService.delete(id, `${ this.configService.Settings.apiServerHost }/${ this.configService.Settings.locationUri }`)
  }

  getAllBoxes(companyId: number): Observable<Box[]> {
    return this.apiService.get(`${ this.configService.Settings.apiServerHost }/${ this.configService.Settings.boxUri }/${ companyId }`)
  }

  getBox(companyId: number, boxId: number) : Observable<Box> {
    return this.apiService.get(`${ this.configService.Settings.apiServerHost }/${ this.configService.Settings.boxUri }/${ companyId }/${ boxId }`);
  }

  getAllPartsInBox(boxId: number): Observable<BoxParts[]> {
    return this.apiService.get(`${ this.configService.Settings.apiServerHost }/${ this.configService.Settings.boxUri }/GetAllPartsInBox/?boxId=${ boxId }`);
  }

  saveBox(box: Box) {
    if (box.id == 0) 
      return this.apiService.post(box, this.configService.Settings.apiServerHost + this.configService.Settings.boxUri);
    else
      return this.apiService.put(box, this.configService.Settings.apiServerHost + this.configService.Settings.boxUri + `/${ box.id }`);
  }

  savePartsInBox(partsInBox: BoxParts[]) {
    return this.apiService.post(partsInBox, this.configService.Settings.apiServerHost + this.configService.Settings.boxUri + '/savePartBoxes');
  }

  deleteBox(id: number) {
    return this.apiService.delete(id, `${ this.configService.Settings.apiServerHost }/${ this.configService.Settings.boxUri }`);
  }

  getBoxHistory(companyId: number, containerId: number) {
    return this.apiService.get(`${ this.configService.Settings.apiServerHost }/${ this.configService.Settings.boxUri }/${ companyId }/container/${ containerId }`);
  }

  removePartFromBox(partInBox: BoxParts) {
    return this.apiService.deleteWithoutId(`${ this.configService.Settings.apiServerHost }/${ this.configService.Settings.boxUri }/removePartFromBox`, partInBox);
  }

  getMoveBoxMaster(companyId: number, moveBoxMasterId: number): Observable<any> {
    return this.apiService.get<any[]>(`${ this.configService.Settings.apiServerHost }/${ this.configService.Settings.moveBoxUri }/${ companyId}/${ moveBoxMasterId }`);
  }

  saveBoxesListed(masterBoxes: MoveBoxMaster, companyId: number) {
    return this.apiService.post(masterBoxes, this.configService.Settings.apiServerHost + this.configService.Settings.moveBoxUri + `/${ companyId }`);
  }

  assignBoxesToUser(companyId: number, userId: number, id: number) {
    return this.apiService.put(null, `${ this.configService.Settings.apiServerHost}/${ this.configService.Settings.moveBoxUri}/${ companyId }/updateuser/${ id }/${ userId }`);
  }
}