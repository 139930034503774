import { InvoiceDetail } from "./invoice.model";
import { Product } from "./product.model";

export class Box {
    id: number = 0;
    companyId: number = 0;
    name: string = '';
    description: string = '';
    barcode: string = '';
    wareHouseId: number = 0;
    locationId: number = 0;
    location: string = '';
    products: Product[] = [];
}

export class ReceiveBox {
    locationId: number = 0;
    supplierInvoiceDetail: InvoiceDetail[] = [];
}

export class BoxHistory {
    id: number = 0;
    companyId: number = 0;
    name: string = '';
    description: string = '';
    barcode: string = '';
    invoiceId: number = 0;
    invoiceNo: string = '';
    boxNumber: number = 0;
    containerId: number = 0;
    containerNo: string = '';
    locationId: number = 0;
    location: string = '';
}